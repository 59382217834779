/* font-family: 'Londrina Solid', cursive;
font-family: 'Yeon Sung', cursive; */

.App{

  
}
.cursor-pointer{
    cursor: pointer;
}
.londrina{
    font-family: 'Londrina Solid'
}
.yeon{
    font-family: 'Yeon Sung'
}
.point{
    cursor: pointer;
}
.image-standard{
    max-width: 100%;
    height: auto;
    margin: auto;
}

.social-icon{
    font-size: 1.5em;
    margin-right: 1em;
}
.logo-font{
    font-family: 'Yeon Sung'
}
.text-indent{
    text-indent: 3rem;
}