

.mynav{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 5rem;
    /* background: linear-gradient(120deg, rgba(255,255,255,1) 0%, rgba(90,90,90,1) 80rem ); */
    background: linear-gradient(120deg, rgba(255,255,255,1) 0%, var(--mdf1) 80rem );
    z-index: 20;
}
.mynav-logo-container{
    display: flex;
    align-items: center;
    width: 30rem;
    height: 5rem;
}
.mynav-logo{
    width: 5rem;
    height: 5rem;
    margin: 0 0.7rem;
    cursor: pointer;
}
.mynav-logo-name{
    width: 22rem;
    font-family: 'Yeon Sung';
    font-size: 1.4em;
    cursor: pointer;
    filter: drop-shadow(1px 1px 1px white)
}
.mynav-links-container{
    align-items: center;
    display: none;
}
.mynav-link{
    margin: 0 1rem;
    font-size: 1.1em;
    cursor: pointer;
    margin: 0.2rem 0;
    margin-left: 0.8rem;
    color: white;
    /* color: rgb(220,220,220); */
    text-align: center;
    font-family: 'Londrina Solid', cursive;
    letter-spacing: 0.07em;
    font-weight: 300;
}
.mynav-link:hover{
    color: white;
}
.mynav-hamburger{
    width: 100%;
    text-align: right;
    margin-right: 1em;
    display: block;
    color: black;
}
.hamburger-icon{
    font-size: 1.5em;
}

.vertical-menu{
    width: 100%;
    /* position: absolute;
    left: 0; 
    top: 5rem;       */
    background: var(--mdf1);
    z-index: 10;
    animation-name: menu2a;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.donate-link{
    position: relative;
    right: -0.7rem;
    background-color: white;
    color: var(--mdf1);
    padding: 0 0.3rem;
    padding-top: 0.2rem; 
    border-radius: 0.5rem;
    width: 8rem;
    margin: auto;

}
.donate-link:hover{
    color: black;
    background-color: silver;
}


.vertical-menu-closed {
    width: 100%;
    /* position: absolute;
    top: 5rem;
    left: 0rem; */
    background: var(--mdf1);
    z-index: 10;
    animation-name: menu2b;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media(min-width:55rem){
    .mynav-link{
        margin-right: 0.3rem;
    }
    .mynav-links-container{
        display: flex;
    }
    .mynav-hamburger{
        display: none;
        animation-name: menu2b;
        animation-duration: 0.7s
    }
    .vertical-menu{
        display: none;
    }
    .mynav{
        /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 50rem); */
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, var(--mdf1) 50rem);
    }
    .mynav-hamburger{
        color: white;
    }
}


@keyframes menu2a {
    from {
        height: 0;
    }
    to {
        height: 20rem;
    }
}
@keyframes menu2b {
    from {
        height: 20rem;
    }
    to {
        height: 0;
    }
}




















