html{
  font-size: 62.5%;
  height: 100%;
  /* --mdf1: rgb(15,95,156); */
  --mdf1: #03254E; /*oxford blue */
  /* --mdf1: red; */
  --mdf2: #545677;
  --mdf3: #EB9FEF;
}
body {

  font-size: 1.6rem;
  margin: 0;
  box-sizing: border-box;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
  By setting the html font-size to 62.5% and the body font size to 1.6 rem,
  when we use css, 10px will be equal to 1rem.
  Otherwise, 1rem is 16px which is a more difficult number to deal with.

  If, for example, you set a box size to 2rem, the size will be 20px.
  For a computer monitor, 100px is about an inch, and so 10rem will be an inch (unless the monitor is scaled)

10px = 1rem; */